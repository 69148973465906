import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getLearningPathData, handleNextStepOpen, handleStepOpen, getCurrentStep } from 'helpers/utils';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import { useAppState } from 'state';

export default function ValuesVips() {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);

	const { setShowCompleteModal } = useAppState();

	const { setStep } = useFirebaseAuth();

	let { id } = useParams();

	const data = getLearningPathData(learningPaths, id, 'values-vips') as any;

	useEffect(() => {
		if (learningPaths) {
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	return (
		<>
			<LearningPath>
				<ReactFullpage
					licenseKey={'YOUR_KEY_HERE'}
					scrollingSpeed={500} /* Options here */
					verticalCentered={false}
					render={({ state, fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								<FullPageSection>
									<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
										<ReactMarkdown className="text-sm sm:text-lg">{data.introduction}</ReactMarkdown>

										<SwipeUpButton />
									</div>
								</FullPageSection>

								{data.quote && (
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
											<ReactMarkdown className="sm:text-lg">{learningPaths ? data.quote : ''}</ReactMarkdown>
											<SwipeUpButton showIcon />
										</div>
									</FullPageSection>
								)}

								<FullPageSection
									linkTo={`/`}
									swipeCallback={async () => {
										if (learningPaths) {
											await localStorage.setItem('step', getCurrentStep(learningPaths, data)?.index + 2);
											await setStep(getCurrentStep(learningPaths, data)?.index + 2);
											await handleNextStepOpen(learningPaths, data, (val) => setStep(val));

											setShowCompleteModal(true);
										}
									}}
								>
									{' '}
									<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
										<ReactMarkdown className="sm:text-lg">{learningPaths ? data.author : ''}</ReactMarkdown>
										<FinishModuleButton />
									</div>
								</FullPageSection>

								{/* end of know news flash pages */}
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</LearningPath>
		</>
	);
}
