import { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ModuleButton from 'components/ModuleButton/ModuleButton';
import backgroundImageLarge from 'assets/image/map-bg.png';
import { smallCoordinates } from './coordinates';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getCurrentStep } from 'helpers/utils';
import FinishOverlay from 'components/FinishOverlay/FinishOverlay';
import flags from 'assets/SVG/flags.png';
import dayjs from 'dayjs';
import CompleteOverlay from 'components/CompleteOverlay/CompleteOverlay';
import DiscoverWovenCityOverlay from 'components/DiscoverWovenCityOverlay/DiscoverWovenCityOverlay';
import { useAppState } from 'state';

const mapReducer = (state, action) => {
	switch (action.type) {
		case 'zoomIn': {
			return { coords: smallCoordinates, moduleSize: 20, mapSize: 'small' };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

const Home = () => {
	const modal: any = useSelector<RootState>((state) => state.modal);
	const [active, setActive] = useState<number>(
		localStorage.getItem('step') ? parseInt(localStorage.getItem('step')) : 0
	);
	const [showHelper, setShowHelper] = useState(!localStorage.getItem('step') || localStorage.getItem('step') === '0');
	const [finishOverlay, setFinishOverlay] = useState<boolean>(false);
	const [canProgress, setCanProgress] = useState<boolean>(false);
	const user = useSelector<RootState>((state) => state.user) as any;
	const [height, setHeight] = useState(window.innerHeight);

	console.log(active)

	const { showCompleteModal, showWovenCityModal } = useAppState();

	const history = useHistory();

	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths) as any;

	const [state] = useReducer(mapReducer, {
		coords: smallCoordinates,
		moduleSize: 20,
		mapSize: 'small',
	});

	console.log('learningPaths', learningPaths);

	// const { moveToNextLevel } = useFirebaseAuth();

	const handleGoClick = async () => {
		if (canProgress) {
			// setCanProgress(false);
			// await moveToNextLevel();
			// setActive(0);
			// window.location.reload();
			// return;
		}

		if (active !== 0 && !getCurrentStep(learningPaths, learningPaths[0].steps[active - 1])?.stepPath) {
			window.location.href = "https://toyota-s9.web.app/";
			return;
		}

		setShowHelper(false);
		history.push(
			`/learning-path/${learningPaths[0].id}/${
				active === 0
					? getCurrentStep(learningPaths, learningPaths[0].steps[0])?.stepPath
					: getCurrentStep(learningPaths, learningPaths[0].steps[active - 1])?.stepPath
			}`
		);
	};

	useEffect(() => {
		if (active === 0 || !active) {
			setActive(user?.demo?.step || 0);
		}

		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		//!PROD
		if (active === 8 && dayjs(learningPaths[0].start_of_next_path).isBefore(dayjs().add(1, 'day'))) {
			//!STAGING
			// if (active === 8 && dayjs(learningPaths[0].start_of_next_path).isBefore(dayjs().add(365, 'day'))) {
			setCanProgress(true);
			// setShowCompleteModal(true);
		}
		// eslint-disable-next-line
	}, [active]);

	const { coords, moduleSize, mapSize } = state;

	const lastButtonIndex =
		learningPaths && learningPaths[0] && learningPaths[0].steps
			? learningPaths[0].steps.length + 1
			: 0;

	const getType = (index) => {
		if (index === active) {
			return 'next';
		} else if (index < active) {
			return 'passed';
		}
		return 'disabled';
	};

	useEffect(() => {
		const updateWindowDimensions = () => {
			const newHeight = window.innerHeight;
			setHeight(newHeight);
		};

		window.addEventListener('resize', updateWindowDimensions);

		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);

	return (
		<div className="p-4 pt-12">
			{showHelper  && <div className="bg-black bg-opacity-50 absolute inset-0 z-20"></div>}

			<div
				style={{
					backgroundImage: `url(${mapSize === 'large' ? backgroundImageLarge : `/bgs/step_${active + 1}.jpg`})`,
					backgroundSize: '100% 107%',
					height: height * 0.65,
					maxHeight: '500px',
				}}
				className="m-auto relative rounded-xl w-full bg-cover bg-gray-100 path-bg -mt-16"
			>
				{finishOverlay && <FinishOverlay setDisplay={setFinishOverlay} />}
				{showCompleteModal && !modal && (
					<CompleteOverlay
					// canProgress={canProgress}
					// setDisplay={setShowCompleteModal}
					// nextLevelDate={dateToWords(learningPaths[0].start_of_next_path)}
					/>
				)}
					{showWovenCityModal && !modal && (
					<DiscoverWovenCityOverlay />
				)}

				<div className="mx-auto relative h-full">
					{learningPaths &&
						learningPaths[0]?.steps.map((module, index) => (
							<Link
								to={
									getType(index + 1) === 'disabled'
										? '#'
										: `/learning-path/${learningPaths[0].id}/${module.__component.split('.')[1]}`
								}
								key={index}
							>
								<ModuleButton
									index={index}
									x={coords[index].x}
									y={coords[index].y}
									size={moduleSize}
									label={
										// module.__component.split('.')[1].split('-').join(' ').toUpperCase() === 'KNOW THE BASICS'
										// 	? 'LIVING VALUES'
										// 	: module.__component.split('.')[1].split('-').join(' ').toUpperCase() === 'VALUES VIEW'
										// 	? 'WINNING MINDSET'
										// 	: module.__component.split('.')[1].split('-').join(' ').toUpperCase() === 'NEWS FLASH'
										// 	? 'VALUES VIPS'
										// 	: module.__component.split('.')[1].split('-').join(' ').toUpperCase() === 'VALUES VOICE NOTES'
										// 	? 'WORLD OF POSSIBILITIES'
										// 	: module.__component.split('.')[1].split('-').join(' ').toUpperCase() === 'WALL OF HAPPINESS'
										// 	? 'THE WOVEN CITY'
										// 	:
										module.__component.split('.')[1].split('-').join(' ').toUpperCase()
									}
									type={getType(index + 1)}
								/>
							</Link>
						))}
					{lastButtonIndex && (
						<span>
							<ModuleButton
								index={7}
								x={coords[7].x}
								y={coords[7].y}
								size={moduleSize}
								label="TROPHY"
								type={8 === active ? 'active' : 'disabled'}
							/>
						</span>
					)}
				</div>
			</div>
			{learningPaths && (
				<div
					style={{ zIndex: '28' }}
					className={`go-btn -mt-16 sm:-mt-16 w-10/12 mx-auto flex items-center relative ${
						Math.ceil((active / 8) * 100) === 100 ? 'complete' : ''
					}`}
					onClick={handleGoClick}
				>
					{showHelper  && (
						<>
							<div className="text-white text-2xl absolute -top-32 w-full text-center left-0 flex items-center flex-col">
								<p className="font-bold">Tap the button to start</p>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-12 w-12 mt-4"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
								</svg>
							</div>
						</>
					)}
					{Math.ceil((active / 8) * 100) === 100 ? (
						<img className="w-16 h-12 my-auto" alt="flags" src={flags} />
					) : (
						<p className="text-4xl font-bold w-1/3">GO!</p>
					)}
					<div className="w-2/3 ml-2">
						{Math.ceil((active / 8) * 100) === 100 ? (
							<div>
								<p>Start your Journey to the Woven City</p>
							</div>
						) : (
							<p className="mb-1 text-xs">{learningPaths[0]?.title}</p>
						)}
						<div className="flex items-center">
							<progress
								className="w-8/12"
								id="file"
								value={active === 0 ? 0 : Math.ceil(((active - 1) / 7) * 100)}
								max="100"
							></progress>
							<p className="text-center ml-2 font-bold">{active === 0 ? 0 : Math.ceil(((active - 1) / 7) * 100)}%</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Home;
