import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import ReactMarkdown from 'react-markdown';

import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import { useAppState } from 'state';
import useFirebaseAuth from 'state/useFirebase/useFirebase';

export default function Intro({ nextPopup }) {
	const { intro } = useAppState();

	const { completeIntro } = useFirebaseAuth();

	return (
		<div style={{ zIndex: '99' }} className="fixed bg-white inset-0 m-auto top-0 left-0">
			<ReactFullpage
				licenseKey={'YOUR_KEY_HERE'}
				scrollingSpeed={500} /* Options here */
				verticalCentered={false}
				render={({ state, fullpageApi }) => {
					return (
						<ReactFullpage.Wrapper>
							{/* Quiz page */}
							<FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_1}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection>
							<FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_2}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection>
							{/* <FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_3}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection> */}
							{/* <FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_4}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection> */}
							{/* <FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_5}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection> */}
							<FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
								linkTo="/"
								swipeCallback={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_3}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection>
						</ReactFullpage.Wrapper>
					);
				}}
			/>
		</div>
	);
}
