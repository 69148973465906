import axios from 'axios';
import * as actionTypes from './actionTypes';
import { setLoading } from './loader';

export function setLearningPaths(payload: any) {
	return {
		type: actionTypes.SET_LEARNING_PATHS,
		payload,
	};
}
export function getLearningPaths() {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			axios.get(`${process.env.REACT_APP_CMS_URL}/season-10-paths?_sort=id:ASC`).then((response) => {
				dispatch(setLearningPaths(response.data));
			});
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(setLoading(false));
		}
	};
}
