import { combineReducers } from 'redux';

// reducers
import loader from './loader';
import user from './user';
import learningPaths from './learningPaths';
import modal from './modal';

export const rootReducer = combineReducers({
	loader,
	user,
	learningPaths,
	modal,
});

export type RootState = ReturnType<typeof rootReducer>;
