import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getCurrentStep, getLearningPathData, handleNextStepOpen, handleStepOpen } from 'helpers/utils';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import KnowledgeQuizForm from 'components/KnowledgeQuizForm/KnowledgeQuizForm';
import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';

export default function LivingValues({ history }) {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths) as any;
	const [data, setData] = useState() as any;
	const [answer, setAnswer] = useState(null);
	const [notFinished, setNotFinished] = useState(false);

	const { setStep, completeLivingValuesQuiz } = useFirebaseAuth();

	let { id } = useParams();
	useEffect(() => {
		setData(getLearningPathData(learningPaths, id, 'living-values'));
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (learningPaths) {
			setNotFinished(parseInt(localStorage.getItem('step')) === getCurrentStep(learningPaths, data)?.index);

			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	useEffect(() => {
		console.log(notFinished);
	}, [notFinished]);

	const swipeCallback = async () => {
		console.log('swipecallback', notFinished, getCurrentStep(learningPaths, data)?.index);
		await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
		await completeLivingValuesQuiz();
	};

	const livingValuesQuizComplete = JSON.parse(localStorage.getItem('user'))?.demo?.livingValuesQuizComplete;

	return (
		<>
			<LearningPath>
				{data && (
					<ReactFullpage
						licenseKey={'YOUR_KEY_HERE'}
						scrollingSpeed={500} /* Options here */
						verticalCentered={false}
						render={({ state, fullpageApi }) => {
							return (
								<ReactFullpage.Wrapper>
									{/* living values page */}
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											<ReactMarkdown className="text-sm sm:text-lg">{data.test_image_1}</ReactMarkdown>
											<SwipeUpButton />
										</div>
									</FullPageSection>
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											<ReactMarkdown className="text-sm sm:text-lg">{data.text_image_2}</ReactMarkdown>
											<SwipeUpButton showIcon />
										</div>
									</FullPageSection>
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											<ReactMarkdown className="text-sm sm:text-lg">{data.text_image_3}</ReactMarkdown>
											<SwipeUpButton showIcon />
										</div>
									</FullPageSection>
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											<ReactMarkdown className="text-sm sm:text-lg">{data.text_image_4}</ReactMarkdown>
											<SwipeUpButton showIcon />
										</div>
									</FullPageSection>
									<FullPageSection
										linkTo={livingValuesQuizComplete ? '/' : null}
										swipeCallback={livingValuesQuizComplete ? swipeCallback : () => {}}
									>
										<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
											<div className="relative" id="video">
												{/* Replace posted and video */}
												<video id="videoPlayer" controls poster={data.video_thumb.url}>
													<source src={data.video.url} type="video/mp4" />
												</video>
											</div>
											{livingValuesQuizComplete ? <FinishModuleButton /> : <SwipeUpButton showIcon />}
										</div>
									</FullPageSection>
									{!livingValuesQuizComplete && (
										<FullPageSection
											promptText="Please select an answer to continue"
											linkTo={answer !== null && `/`}
											swipeCallback={swipeCallback}
											promptActive={answer === null}
										>
											<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
												<h2 className="text-lg sm:text-2xl text-lightGray font-bold">Question</h2>

												<p className="sm:text-lg">{data?.question?.question_text}</p>
												<KnowledgeQuizForm
													answers={data?.question?.answers}
													isImageQuestion={data?.question?.answers[0].type === 'image'}
													onSelect={(answer) => {
														setAnswer(answer);
													}}
												/>
												{answer !== null ? <FinishModuleButton /> : <SelectAnswerButton />}
											</div>
										</FullPageSection>
									)}
									{/* end of living values pages */}
								</ReactFullpage.Wrapper>
							);
						}}
					/>
				)}
			</LearningPath>
		</>
	);
}
