import Single360Media from 'components/Single360Media/Single360Media';
import React, { useCallback, useState } from 'react';
import { useAppState } from 'state';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import InfiniteScroll from 'react-infinite-scroll-component';

interface Video {
	thumbnailUrl: string;
	url: string;
}

const Media360 = () => {
	const [selected, setSelected] = useState(null);

	const [dataLength, setDataLength] = useState<number>(0);
	const [data, setData] = useState<Video[]>([]);

	const { setShowWovenCityModal } = useAppState();

	const { get360Videos, get360VideosLength } = useFirebaseAuth();

	React.useEffect(() => {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const activateModal = params.get('activateModal');

		if (activateModal && activateModal === 'true') {
			setShowWovenCityModal(true);
		}

		get360Videos(0, 15).then((res) => {
			setData(res as Video[]);
		});
		get360VideosLength().then((res) => {
			setDataLength(res as number);
		});
		// eslint-disable-next-line
	}, []);

	const getMore = useCallback(() => {
		get360Videos(data[data.length - 1], 15).then((res) => {
			setData((p) => [...p, ...(res as Video[])]);
		});
	}, [data, get360Videos]);

	return (
		<div className="pb-10">
			{selected && <Single360Media data={selected} close={() => setSelected(null)} />}
			<h1 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase mb-8">360 MEDIA</h1>
			<div className="mb-20">
				<InfiniteScroll
					className="grid grid-cols-3"
					dataLength={data.length}
					next={getMore}
					hasMore={dataLength > data.length}
					loader={<div className="col-span-full text-center my-2">Loading...</div>}
				>
					{data.map((item, index) => (
						<div className="w-full h-32" key={index} onClick={() => setSelected(item)}>
							<img src={item.thumbnailUrl} alt={`poster-${index}`} className="w-full h-full object-cover" />
						</div>
					))}
				</InfiniteScroll>
			</div>
		</div>
	);
};

export default Media360;
