import React from 'react';
import confetti from 'canvas-confetti';
import levelComplete from 'assets/image/level_complete.jpeg';

const CompleteOverlay = () => {
	React.useEffect(() => {
		var duration = 8 * 1000;
		var animationEnd = Date.now() + duration;
		var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

		function randomInRange(min, max) {
			return Math.random() * (max - min) + min;
		}

		var interval = setInterval(function () {
			var timeLeft = animationEnd - Date.now();
			// var timeLeft = animationEnd;

			if (timeLeft <= 0) {
				return clearInterval(interval);
			}

			var particleCount = 50 * (timeLeft / duration);
			// since particles fall down, start a bit higher than random
			confetti(
				Object.assign({}, defaults, {
					particleCount,
					origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
					zIndex: 999,
				})
			);
			confetti(
				Object.assign({}, defaults, {
					particleCount,
					origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2, zIndex: 999 },
				})
			);
		}, 250);
	}, []);

	return (
		<div
			style={{ zIndex: 100 }}
			className="m-auto inset-0 fixed bg-black bg-opacity-80 flex items-center justify-center"
		>
			<div className="bg-white rounded-lg text-center w-10/12">
				<img src={levelComplete} className="w-full rounded-t-lg" alt="level complete artwork" />
				<div className="p-4">
					<p className="text-lg">LEVEL COMPLETE!</p>
					<p className="text-2xl text-gray-400 font-bold">360º Photos Unlocked</p>

					{/* <p className="text-lg">Click for full experience</p> */}
					<a
						href="/360-media?activateModal=true"
						className={` bg-gradient-to-r from-purple to-darkBlue mx-auto mt-4 rounded-full text-white text-lg uppercase px-12 py-3 text-center flex items-center justify-center cursor-pointer`}
					>
						View Photos Now
					</a>
				</div>
			</div>
		</div>
	);
};

export default CompleteOverlay;
