import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getCurrentStep, getLearningPathData, handleNextStepOpen, handleStepOpen } from 'helpers/utils';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import KnowledgeQuizForm from 'components/KnowledgeQuizForm/KnowledgeQuizForm';
import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';

const WorldOfPossibilities = () => {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);
	const [answer, setAnswer] = useState(null);
	const [notFinished, setNotFinished] = useState(false);

	let { id } = useParams();

	const data = getLearningPathData(learningPaths, id, 'world-of-possibilities') as any;
	const { setStep, completeWorldOfPossibilitiesQuiz } = useFirebaseAuth();

	useEffect(() => {
		if (learningPaths) {
			setNotFinished(parseInt(localStorage.getItem('step')) - 1 === getCurrentStep(learningPaths, data)?.index);
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	useEffect(() => {
		console.log('Not finished: ', notFinished);
	}, [notFinished]);

	const swipeCallback = async () => {
		await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
		await completeWorldOfPossibilitiesQuiz();
	};

	const worldOfPossibilitiesQuizComplete = JSON.parse(localStorage.getItem('user'))?.demo
		?.worldOfPossibilitiesQuizComplete;

	return (
		<>
			<LearningPath>
				{data && (
					<ReactFullpage
						licenseKey={'YOUR_KEY_HERE'}
						scrollingSpeed={500} /* Options here */
						verticalCentered={false}
						render={({ state, fullpageApi }) => {
							return (
								<ReactFullpage.Wrapper>
									{/* world of possibilities page */}
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
											<ReactMarkdown className="text-sm sm:text-lg">{data.introduction}</ReactMarkdown>
											<SwipeUpButton />
										</div>
									</FullPageSection>

									<FullPageSection
										linkTo={worldOfPossibilitiesQuizComplete ? '/' : null}
										swipeCallback={worldOfPossibilitiesQuizComplete ? swipeCallback : () => {}}
									>
										<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
											<div className="relative" id="video">
												<video id="videoPlayer" controls poster={data.video_thumb.url}>
													<source src={data?.video?.url} type="video/mp4" />
												</video>
											</div>
										</div>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 flex flex-col h-auto">
											<ReactMarkdown>{learningPaths ? data.example : ''}</ReactMarkdown>
											{worldOfPossibilitiesQuizComplete ? <FinishModuleButton /> : <SwipeUpButton showIcon />}
										</div>
									</FullPageSection>
									{/* {data?.video_2 && (
										<FullPageSection
											linkTo={worldOfPossibilitiesQuizComplete ? '/' : null}
											swipeCallback={worldOfPossibilitiesQuizComplete ? swipeCallback : () => {}}
										>
											<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
												<div className="relative" id="video2">
													<video id="videoPlayer" controls poster={data.video_thumb_2.url}>
														<source src={data?.video_2?.url} type="video/mp4" />
													</video>
												</div>
											</div>

											<div className="w-full md:w-60 justify-center items-center px-2 py-6 flex flex-col h-auto">
												<ReactMarkdown>{learningPaths ? data.example : ''}</ReactMarkdown>
												{worldOfPossibilitiesQuizComplete ? <FinishModuleButton /> : <SwipeUpButton showIcon />}
											</div>
										</FullPageSection>
									)} */}
									{!worldOfPossibilitiesQuizComplete && (
										<FullPageSection
											promptText="Please select an answer to continue"
											linkTo={answer !== null && `/`}
											swipeCallback={swipeCallback}
											promptActive={answer === null}
										>
											<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
												<h2 className="text-lg sm:text-2xl text-lightGray font-bold">Question</h2>

												<p className="sm:text-lg">{data?.question?.question_text}</p>
												<KnowledgeQuizForm
													answers={data?.question?.answers}
													isImageQuestion={data?.question?.answers[0]?.type === 'image'}
													onSelect={(answer) => {
														setAnswer(answer);
													}}
												/>
												{answer !== null ? <FinishModuleButton /> : <SelectAnswerButton />}
											</div>
										</FullPageSection>
									)}
									{/* end of world of possibilities pages */}
								</ReactFullpage.Wrapper>
							);
						}}
					/>
				)}
			</LearningPath>
		</>
	);
};

export default WorldOfPossibilities;
