import axios from 'axios';
import React from 'react';
import ReactMarkdown from 'react-markdown';

export default function About() {
	const [about, setAbout] = React.useState();

	React.useEffect(() => {
		axios.get(process.env.REACT_APP_CMS_URL + '/infos').then((res) => {
			setAbout(res.data[0].Content);
		});
	}, []);

	return (
		<div className="px-8 pb-20 about pt-12">
			<ReactMarkdown>{about}</ReactMarkdown>
		</div>
	);
}
