import React from 'react';

import { Route } from 'react-router-dom';
import { Home, Leaderboard, About, ValuesVoiceNotes } from './pages';
import QuickQuiz from './pages/LearningPaths/QuickQuiz/QuickQuiz';
import LivingValues from './pages/LearningPaths/LivingValues/LivingValues';
import WinningMindset from './pages/LearningPaths/WinningMindset/WinningMindset';
import WorldOfPossibilities from './pages/LearningPaths/WorldOfPossibilities/WorldOfPossibilities';
import KnowledgeQuiz from './pages/LearningPaths/KnowledgeQuiz/KnowledgeQuiz';
import ValuesVips from './pages/LearningPaths/ValuesVips/ValuesVips';
import Teaser from './pages/LearningPaths/Teaser/Teaser';
import TheWovenCity from './pages/LearningPaths/TheWovenCity/TheWovenCity';
import Media360 from './pages/360Media/360Media';

export default function Routes() {
	return (
		<>
			<Route path="/learning-path/:id/living-values" component={LivingValues} />
			<Route path="/learning-path/:id/quick-quiz" component={QuickQuiz} />
			<Route path="/learning-path/:id/winning-mindset" component={WinningMindset} />
			<Route path="/learning-path/:id/the-woven-city" component={TheWovenCity} />
			<Route path="/learning-path/:id/world-of-possibilities" component={WorldOfPossibilities} />
			<Route path="/learning-path/:id/knowledge-quiz" component={KnowledgeQuiz} />
			<Route path="/learning-path/:id/values-vips" component={ValuesVips} />
			<Route path="/learning-path/:id/teaser" component={Teaser} />
			<Route path="/leaderboard" component={Leaderboard} />
			<Route path="/about" component={About} />
			<Route path="/values-voice-notes" component={ValuesVoiceNotes} />
			<Route path="/360-media" component={Media360} />

			<Route path="/" exact component={Home} />
		</>
	);
}
