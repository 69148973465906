import { useState } from 'react';

export default function KnowledgeQuizForm({ answers = [], isImageQuestion, onSelect }) {
	const [selectedAnswer, setSelectedAnswer] = useState(null);

	const createAnswerStyle = (answer) => {
		if (selectedAnswer === answer.id && answer.is_correct_answer) {
			return 'text-toyotaGreen font-bold';
		} else if (selectedAnswer === answer.id) {
			return 'text-red-500';
		} else if (selectedAnswer !== null && answer.is_correct_answer) {
			return 'text-toyotaGreen font-bold';
		}
		return '';
	};

	return (
		<div>
			{isImageQuestion ? (
				{}
			) : (
				<div className="knowledge-quiz">
					{answers.map((answer, index) => (
						<div
							key={answer.id}
							className={`flex items-center ${createAnswerStyle(answer)}`}
							onClick={() => {
								setSelectedAnswer((curr) => {
									if (curr) {
										return curr;
									} else {
										onSelect(answer.is_correct_answer);
										return answer.id;
									}
								});
							}}
						>
							<div
								className={`p-2 rounded-full bg-gray-300 ${
									selectedAnswer === answer.id && answer.is_correct_answer && 'bg-toyotaGreen'
								}`}
							></div>
							<p className="ml-6 sm:text-base">{answer.answer_text}</p>
						</div>
					))}
				</div>
			)}
		</div>
	);
}
