import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';

import { getCurrentStep, getLearningPathData, handleNextStepOpen, handleStepOpen } from 'helpers/utils';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import KnowledgeQuizForm from 'components/KnowledgeQuizForm/KnowledgeQuizForm';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';

export default function KnowledgeQuiz() {
	// const { learningPaths } = useAppState();
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);

	const { setStep, addQuizScore } = useFirebaseAuth();
	let { id } = useParams();
	const data = getLearningPathData(learningPaths, id, 'knowledge-quiz') as any;
	const [answers, setAnswers] = useState([]);
	const [quizScore, setQUizScore] = useState(null);


	useEffect(() => {
		if (learningPaths) {
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	useEffect(() => {
		setQUizScore(JSON.parse(localStorage.getItem('user'))?.demo?.quizScore);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const swipeCallback = async () => {
		await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
	};

	const handleQuizEnd = (result, isPerfect) => {
		addQuizScore(result, isPerfect);
	};

	return (
		<>
			<LearningPath>
				{data && (
					<ReactFullpage
						licenseKey={'YOUR_KEY_HERE'}
						scrollingSpeed={500} /* Options here */
						verticalCentered={false}
						onLeave={(origin, destination, direction) => {
							if (destination.index < origin.index) {
								return false;
							}

							if (answers[origin.index - 1] === undefined && origin.index !== 0) {
								return false;
							}
						}}
						render={({ state, fullpageApi }) => {
							return (
								<ReactFullpage.Wrapper>
									{/* Quiz page */}

									{parseInt(localStorage.getItem('step')) <= getCurrentStep(learningPaths, data).index + 1 &&
										!quizScore && (
											<FullPageSection>
												<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
													<div className="mt-0">
														{learningPaths && (
															<img src={data?.featured_image?.url} alt="Knowledge quiz" className="w-8/12 mx-auto" />
														)}
													</div>
													<p className="py-4 px-4 text-center sm:text-lg">{learningPaths ? data.introduction : ''}</p>

													<SwipeUpButton />
												</div>
											</FullPageSection>
										)}

									{parseInt(localStorage.getItem('step')) <= getCurrentStep(learningPaths, data).index + 1 &&
										!quizScore &&
										learningPaths &&
										data.question.map((question, index) => (
											<FullPageSection
												promptText="Please select an answer to continue"
												promptActive={answers[index] !== undefined ? false : true}
												key={index}
											>
												<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
													<h2 className="text-lg sm:text-2xl text-lightGray font-bold">Question {index + 1}</h2>

													<p className="sm:text-lg">{question.question_text}</p>
													<KnowledgeQuizForm
														answers={question.answers}
														isImageQuestion={question.answers[0]?.type === 'image'}
														onSelect={(answer) => {
															let newAnswers;
															if (answers[index]) {
																newAnswers = [...answers.slice(0, index), answer, ...answers.slice(index + 1)];
															} else {
																newAnswers = [...answers, answer];
															}
															setAnswers(newAnswers);
															if (index === data.question.length - 1) {
																const isPerfect =
																	newAnswers.filter((x) => x === true).length / data.question.length === 1;
																handleQuizEnd(
																	`${newAnswers.filter((x) => x === true).length}/${data.question.length}`,
																	isPerfect
																);
															}
														}}
													/>
													<div className="pt-2 flex flex-col items-center">
														<p className="text-lightGray">
															{index + 1} / {data.question.length}
														</p>
													</div>
													{answers[index] !== undefined ? <SwipeUpButton /> : <SelectAnswerButton />}
												</div>
											</FullPageSection>
										))}
									<FullPageSection linkTo={`/`} swipeCallback={swipeCallback}>
										<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
											<div>
												<img
													className="py-4"
													src={
														data.results.filter(
															(x) =>
																answers.filter((x) => x === true).length >= x.range_start &&
																answers.filter((x) => x === true).length <= x.range_end
														)[0].result_image?.url
													}
													alt=""
												/>
												<p className="px-4 text-center">
													{
														data.results.filter(
															(x) =>
																answers.filter((x) => x === true).length >= x.range_start &&
																answers.filter((x) => x === true).length <= x.range_end
														)[0].result_text
													}
												</p>
												<h2 className="text-lg sm:text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">
													your score is{' '}
													<span className="font-bold text-toyotaGreen">
														{!localStorage.getItem('quiz-score')
															? `${answers.filter((x) => x === true).length}/${data.question.length}`
															: localStorage.getItem('quiz-score')}
													</span>
												</h2>
											</div>
											<FinishModuleButton />
										</div>
									</FullPageSection>
								</ReactFullpage.Wrapper>
							);
						}}
					/>
				)}
			</LearningPath>
		</>
	);
}
