import React, { useState } from 'react';
import axios from 'axios';

export default function useValeusVideos() {
	const [loadingVNs, setLoadingVNs] = useState<boolean>(false);
	const [valuesVideos, setvaluesVideos] = useState<any>([]);

	React.useEffect(() => {
		setLoadingVNs(true);
		axios.get(`${process.env.REACT_APP_CMS_URL}/s-10-videos-listing`).then((response) => {
			setvaluesVideos(response.data?.Videos_listing);
			setLoadingVNs(false);
		});
	}, []);

	return {
		loadingVNs,
		valuesVideos,
	};
}
