import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import ReactMarkdown from 'react-markdown';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getLearningPathData, handleNextStepOpen } from 'helpers/utils';
import { useParams } from 'react-router-dom';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import KnowledgeQuizForm from 'components/KnowledgeQuizForm/KnowledgeQuizForm';
import { useState } from 'react';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';
import useFirebaseAuth from 'state/useFirebase/useFirebase';

const TheWovenCity = () => {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);
	const [answer, setAnswer] = useState(null);

	let { id } = useParams();

	const data = getLearningPathData(learningPaths, id, 'the-woven-city') as any;

	const { setStep, completeTheWovenCityQuiz } = useFirebaseAuth();

	const swipeCallback = async () => {
		await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
		await completeTheWovenCityQuiz();
	};

	const theWovenCityQuizComplete = JSON.parse(localStorage.getItem('user'))?.demo?.theWovenCityQuizComplete;

	return (
		<LearningPath>
			<ReactFullpage
				licenseKey={'YOUR_KEY_HERE'}
				scrollingSpeed={500} /* Options here */
				verticalCentered={false}
				render={({ state, fullpageApi }) => {
					return (
						<ReactFullpage.Wrapper>
							{/* know the basics page */}
							<FullPageSection>
								<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown className="text-sm sm:text-lg">{data.introduction}</ReactMarkdown>
									<SwipeUpButton />
								</div>
							</FullPageSection>
							<FullPageSection
								linkTo={theWovenCityQuizComplete ? '/' : null}
								swipeCallback={theWovenCityQuizComplete ? swipeCallback : () => {}}
							>
								<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
									<div className="relative" id="video2">
										<video id="videoPlayer" controls poster={data.video_thumb.url}>
											<source src={data?.video?.url} type="video/mp4" />
										</video>
									</div>
								</div>

								<div className="w-full md:w-60 justify-center items-center px-2 py-6 flex flex-col h-auto">
									<ReactMarkdown>{learningPaths ? data.example : ''}</ReactMarkdown>
									{theWovenCityQuizComplete ? <FinishModuleButton /> : <SwipeUpButton showIcon />}
								</div>
							</FullPageSection>
							{!theWovenCityQuizComplete && (
								<FullPageSection
									promptText="Please select an answer to continue"
									linkTo={answer !== null && `/`}
									swipeCallback={swipeCallback}
									promptActive={answer === null}
								>
									<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
										<h2 className="text-lg sm:text-2xl text-lightGray font-bold">Question</h2>

										<p className="sm:text-lg">{data?.question?.question_text}</p>
										<KnowledgeQuizForm
											answers={data?.question?.answers}
											isImageQuestion={data?.question?.answers[0].type === 'image'}
											onSelect={(answer) => {
												setAnswer(answer);
											}}
										/>
										{answer !== null ? <FinishModuleButton /> : <SelectAnswerButton />}
									</div>
								</FullPageSection>
							)}
							{/* end of know the basics pages */}
						</ReactFullpage.Wrapper>
					);
				}}
			/>
		</LearningPath>
	);
};

export default TheWovenCity;
