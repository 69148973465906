import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

export const StateContext = createContext(null);

export default function AppStateProvider({ children }) {
	const [isFetching, setIsFetching] = useState(false);
	const [showCommentsPanel, setShowCommentsPanel] = useState<boolean>(false);
	const [selectedTask, setSelectedTask] = useState(null);
	const [showCompleteModal, setShowCompleteModal] = useState(false);
	const [intro, setIntro] = useState(null);
	const [activeLevel, setActiveLevel] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [showWovenCityModal, setShowWovenCityModal] = useState(false);

	React.useEffect(() => {
		console.log('Setting active level in state: ', activeLevel);
	}, [activeLevel]);

	React.useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_CMS_URL}/s-10-intro`)
			.then((res) => {
				setIntro(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	let contextValue = {
		showCommentsPanel,
		setShowCommentsPanel,
		selectedTask,
		setSelectedTask,
		showCompleteModal,
		setShowCompleteModal,
		intro,
		isFetching,
		setIsFetching,
		activeLevel,
		setActiveLevel,
		openModal,
		setOpenModal,
		showWovenCityModal,
		setShowWovenCityModal
	};

	contextValue = {
		...contextValue,
	};

	return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useAppState() {
	const context = useContext(StateContext);
	if (!context) {
		throw new Error('useAppState must be used within the AppStateProvider');
	}
	return context;
}
