import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getLearningPathData, handleNextStepOpen, handleStepOpen } from 'helpers/utils';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import KnowledgeQuizForm from 'components/KnowledgeQuizForm/KnowledgeQuizForm';
import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';
import ReactMarkdown from 'react-markdown';

const ValuesView = () => {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);
	const [answer, setAnswer] = useState(null);

	let { id } = useParams();

	const { setStep, completeWinningMindsetQuiz } = useFirebaseAuth();

	const data = getLearningPathData(learningPaths, id, 'winning-mindset') as any;

	useEffect(() => {
		if (learningPaths) {
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	const swipeCallback = async () => {
		await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
		await completeWinningMindsetQuiz();
	};

	const winningMindsetQuizComplete = JSON.parse(localStorage.getItem('user'))?.demo?.winningMindsetQuizComplete;

	return (
		<>
			<LearningPath>
				<ReactFullpage
					licenseKey={'YOUR_KEY_HERE'}
					scrollingSpeed={500} /* Options here */
					verticalCentered={false}
					render={({ state, fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								{/* know values view page */}
								<FullPageSection>
									<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
										<ReactMarkdown className="text-sm sm:text-lg">{data.introduction}</ReactMarkdown>
										<SwipeUpButton />
									</div>
								</FullPageSection>

								{/* TODO: change from api when it is added */}
								<FullPageSection
									swipeCallback={winningMindsetQuizComplete ? swipeCallback : () => {}}
									linkTo={winningMindsetQuizComplete ? '/' : null}
								>
									<div className="w-full md:w-60 h-3/4 justify-center items-center px-6 py-6 flex flex-col">
										<div className="relative" id="video">
											<video id="videoPlayer" controls poster={data.video_thumb.url}>
												<source src={learningPaths ? data.video?.url : ''} type="video/mp4" />
											</video>
										</div>
										{winningMindsetQuizComplete ? <FinishModuleButton /> : <SwipeUpButton showIcon />}
									</div>
								</FullPageSection>

								{/* {data?.video_2 && (
									<FullPageSection
										swipeCallback={winningMindsetQuizComplete ? swipeCallback : () => {}}
										linkTo={winningMindsetQuizComplete ? '/' : null}
									>
										<div className="w-full md:w-60 h-3/4 justify-center items-center px-6 py-6 flex flex-col">
											<div className="relative" id="video">
												<video id="videoPlayer" controls poster={data.video_thumb_2.url}>
													<source src={learningPaths ? data.video_2?.url : ''} type="video/mp4" />
												</video>
											</div>
											{winningMindsetQuizComplete ? <FinishModuleButton /> : <SwipeUpButton showIcon />}
										</div>
									</FullPageSection>
								)} */}
								{!winningMindsetQuizComplete && (
									<FullPageSection
										promptText="Please select an answer to continue"
										linkTo={answer !== null && `/`}
										swipeCallback={swipeCallback}
										promptActive={answer === null}
									>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											<h2 className="text-lg sm:text-2xl text-lightGray font-bold">Question</h2>

											<p className="sm:text-lg">{data?.question?.question_text}</p>
											<KnowledgeQuizForm
												answers={data?.question?.answers}
												isImageQuestion={data?.question?.answers[0]?.type === 'image'}
												onSelect={(answer) => {
													setAnswer(answer);
												}}
											/>
											{answer !== null ? <FinishModuleButton /> : <SelectAnswerButton />}
										</div>
									</FullPageSection>
								)}

								{/* end of values view pages */}
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</LearningPath>
		</>
	);
};

export default ValuesView;
