import { NavLink } from 'react-router-dom';
import { HomeIcon, InfoIcon, LeaderBoardIcon, ValuesVoicenotesIcon, WallOfHappinessIcon } from './NavIcons';
import { useLocation } from 'react-router-dom';

const hideNavURLS = ['learning-path'];

interface INavItem {
	title: string;
	url: string;
	icon: any;
	isActive?: (match, location) => boolean;
}

export const navItems: INavItem[] = [
	{
		title: 'Leaderboard',
		url: '/leaderboard',
		icon: LeaderBoardIcon,
	},
	{
		title: 'Wall of Happiness',
		url: '/360-media',
		icon: WallOfHappinessIcon,
	},
	{
		title: 'Home',
		url: '/',
		icon: HomeIcon,
		isActive: (match, location) => {
			return location.pathname === '/' || location.pathname.includes('/learning-path');
		},
	},
	{
		title: 'Values Voicenotes',
		url: '/values-voice-notes',
		icon: ValuesVoicenotesIcon,
	},
	{
		title: 'Info',
		url: '/about',
		icon: InfoIcon,
	},
];

export default function NavBar() {
	const location = useLocation();

	let hideNav = false;

	hideNavURLS.map((url) => {
		if (location.pathname.includes(url)) {
			hideNav = true;
		}
		return true;
	});
	return (
		<>
			{hideNav ? null : (
				<nav className="w-full bg-white fixed bottom-0 h-20 flex flex-row z-20">
					{navItems.map((item, i) => (
						<div
							className="w-1/5 flex items-center justify-center cursor-pointer text-darkGray transform rotate-45 "
							key={i}
						>
							<NavLink
								className="p-3 rounded-2xl"
								activeClassName="bg-darkBlue text-white"
								to={item.url}
								isActive={item.isActive}
							>
								<item.icon />
							</NavLink>
						</div>
					))}
				</nav>
			)}
		</>
	);
}
