import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import { RootState } from 'store/reducers';
import dealersData from 'dealersData.json';

export default function Leaderboard() {
	const { getLeaderboard } = useFirebaseAuth();

	const user: any = useSelector<RootState>((state) => state.user);

	const [users, setUsers] = useState([]);
	const [showAll, setShowAll] = useState(false);

	useEffect(() => {
		if (user) {
			(async function () {
				setUsers(await getLeaderboard(user.dealerCode));
			})();
		}
		// eslint-disable-next-line
	}, [user]);

	return (
		<div className="w-full md:w-60 justify-center items-center py-6 px-2 pb-20 flex flex-col h-auto">
			<h2 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">Finishers</h2>
			<div className="w-full md:w-60 justify-center items-center px-2">
				<div>
					<ul className="flex flex-col bg-white-200 p-2">
						{users &&
							users.slice(0, !showAll ? 5 : users.length).map((user, index) => (
								<li className="select-none cursor-pointer flex-1 items-center px-0 py-1 border-b-2 flex flex-row">
									{/* <div className="flex flex-col justify-items-start items-start mr-4">
										<h3 className="text-red-400 font-semibold w-7 text-center">{index + 1}</h3>
									</div> */}
									<div className="flex-1 pl-1 mr-16 py-2">
										<div className="font-medium">{user.fullname}</div>
										<div className="text-sm text-gray-600">
											{dealersData.find((el) => el.code === user.dealerCode)?.name}
										</div>

										<div className="text-gray-600 text-sm flex items-center justify-items-start"></div>
									</div>
								</li>
							))}
					</ul>
					{!showAll && users?.length > 5 && (
						<h3 className="uppercase text-darkBlue text-md my-5 font-bold py-2 px-2" onClick={() => setShowAll(true)}>
							show more
						</h3>
					)}
				</div>
			</div>
		</div>
	);
}
