import { useEffect, useState } from 'react';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/actions/user';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useAppState } from 'state';
dayjs.extend(weekOfYear);

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};

export const activeWeek = 'week1';

export default function useFirebaseAuth() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);

	const { setActiveLevel } = useAppState();

	useEffect(() => {
		app.initializeApp(firebaseConfig);
	}, []);

	const getLeaderboard = async (dealerCode) => {
		try {
			let data = [];
			const snapshot = await app
				.firestore()
				.collection('users')
				.where('fullname', '!=', 'Elmarie Vos')
				// .orderBy('achievements', 'desc')
				.get();

			snapshot.forEach((doc) => {
				data.push(doc.data());
			});

			return data.filter((user) => user?.demo?.step === 8);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const setStep = async (step: number) => {
		// setIsFetching(true);
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					demo: {
						...user?.demo,
						step,
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setIsFetching(false);
		}
	};

	// const moveToNextLevel = async () => {
	// 	try {
	// 		const user = JSON.parse(localStorage.getItem('user'));
	// 		user.activeLevel = parseInt(user.activeLevel) + 1;

	// 		setActiveLevel(user.activeLevel);
	// 		user.weeks[`week` + (parseInt(user.activeLevel) + 1)] = {
	// 			step: 0,
	// 		};

	// 		await app
	// 			.firestore()
	// 			.collection('users')
	// 			.doc(user.uid)
	// 			.set({ ...user });

	// 		localStorage.setItem('user', JSON.stringify(user));
	// 		// localStorage.setItem('step', 0 + '');
	// 		await signIn(user.idNumber);
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };

	const addQuizScore = async (score, isPerfect) => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			let newUser = {
				...user,
				demo: {
					...user?.demo,
					quizScore: score,
				},
			};

			await localStorage.setItem('user', JSON.stringify(newUser));

			await app.firestore().collection('users').doc(user.uid).set(newUser);

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeKnowTheBasicsQuiz = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			console.log('called know the basics');
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					demo: {
						...user?.demo,
						knowTheBasicsQuizComplete: true,
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeLivingValuesQuiz = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			console.log('called living values');
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					demo: {
						...user?.demo,
						livingValuesQuizComplete: true,
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeValuesViewQuiz = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			console.log('called complete values view quiz');
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					demo: {
						...user?.demo,
						valuesViewQuizComplete: true,
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeWinningMindsetQuiz = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			console.log('called complete winning mindset quiz');
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					demo: {
						...user?.demo,
						winningMindsetQuizComplete: true,
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeValuesVoiceNoteQuiz = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			console.log('called values voice note');
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					demo: {
						...user?.demo,
						valuesVoiceNoteQuizComplete: true,
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeWorldOfPossibilitiesQuiz = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			console.log('called world of possibilities note');
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					demo: {
						...user?.demo,
						worldOfPossibilitiesQuizComplete: true,
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeTheWovenCityQuiz = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			console.log('called the woven city note');
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					demo: {
						...user?.demo,
						theWovenCityQuizComplete: true,
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const register = async (uid, type, fullname, idNumber, dealerCode) => {
		try {
			setLoading(true);

			const timestamp = app.firestore.FieldValue.serverTimestamp();

			let user = null;
			const snapshot = await app.firestore().collection('users').where('idNumber', '==', idNumber).limit(1).get();
			snapshot.forEach((doc) => {
				user = doc.data();
			});

			dealerCode = parseInt(dealerCode);

			if (user) {
				await signIn(idNumber);
			} else {
				await app.firestore().collection('users').doc(uid).set({
					uid,
					type,
					fullname,
					idNumber,
					dealerCode,
					achievements: [],
					weeks: [],
					timestamp,
					demo: {},
				});

				await signIn(idNumber);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const signIn = async (idNumber) => {
		try {
			setLoading(true);

			let user = null;

			const snapshot = await app.firestore().collection('users').where('idNumber', '==', idNumber).limit(1).get();

			snapshot.forEach((doc) => {
				user = doc.data();
			});

			if (!user) {
				localStorage.removeItem('user');
				window.location.href = window.location.pathname;
			} else {
				let newUser = { ...user };

				if (!user.demo) {
					await app
						.firestore()
						.collection('users')
						.doc(user.uid)
						.set({
							...user,
							demo: {},
						});
					newUser = { ...user, demo: {} };
				}

				if (!newUser.activeLevel) {
					newUser.activeLevel = 0;
				}

				setActiveLevel(0);

				newUser.last_active = app.firestore.FieldValue.serverTimestamp();

				await app.firestore().collection('users').doc(user.uid).set(newUser);

				dispatch(setUser(newUser));

				localStorage.setItem('step', user?.demo?.step || 0);

				if (user?.demo?.quizScore) {
					localStorage.setItem('quiz-score', user?.demo?.quizScore);
				}
				if (user?.demo?.knowTheBasicsQuizComplete) {
					localStorage.setItem('know-the-basics-quiz-complete', user?.demo?.knowTheBasicsQuizComplete);
				}
				if (user?.demo?.valuesViewQuizComplete) {
					localStorage.setItem('values-view-quiz-complete', user?.demo?.valuesViewQuizComplete);
				}
				if (user?.demo?.valuesVoiceNoteQuizComplete) {
					localStorage.setItem('values-voice-note-quiz-complete', user?.demo?.valuesVoiceNoteQuizComplete);
				}
				if (user?.demo?.completedIntro) {
					localStorage.setItem('completed-intro', user?.demo?.completedIntro);
				}
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const completeIntro = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			console.log('called complete intro');
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					demo: {
						...user?.demo,
						completedIntro: true,
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const get360VideosLength = async () => {
		return new Promise((resolve, reject) => {
			app
				.firestore()
				.collection('s10_videos')
				.get()
				.then((snapshot) => {
					resolve(snapshot.size);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const get360Videos = async (startAfter: any, limit: number) => {

		return new Promise((resolve, reject) => {
			if (startAfter) {
				app
					.firestore()
					.collection('s10_videos')
					.limit(limit)
					.orderBy('createdAt', 'desc')
					.startAfter(startAfter.createdAt)
					.get()
					.then((snapshot) => {
						let videos = [];
						snapshot.forEach((doc) => {
							videos.push(doc.data());
						});
						resolve(videos);
					})
					.catch((err) => {
						reject(err);
					});
			} else {
				app
					.firestore()
					.collection('s10_videos')
					.limit(limit)
					.orderBy('createdAt', 'desc')
					.get()
					.then((snapshot) => {
						let videos = [];
						snapshot.forEach((doc) => {
							videos.push(doc.data());
						});
						resolve(videos);
					})
					.catch((err) => {
						reject(err);
					});
			}
		});
	};

	return {
		register,
		signIn,
		setStep,
		getLeaderboard,
		completeKnowTheBasicsQuiz,
		completeValuesViewQuiz,
		completeValuesVoiceNoteQuiz,
		completeWorldOfPossibilitiesQuiz,
		completeTheWovenCityQuiz,
		completeIntro,
		activeWeek,
		// moveToNextLevel,
		loading,
		addQuizScore,
		completeLivingValuesQuiz,
		completeWinningMindsetQuiz,
		get360VideosLength,
		get360Videos,
	};
}
