import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import { UID } from 'hooks/useUID/useUID';

export default function UserCheck() {
	const { register } = useFirebaseAuth();
	// eslint-disable-next-line
	const location = useLocation();

	useEffect(() => {
		(async function () {
			if (window.location.hash.includes('#id_token')) {
				window.location.search = window.location.hash.replace('#', '?');
			}
			const query = new URLSearchParams(location.search);
			const idToken = query.get('id_token');

			if (idToken) {
				const decodedToken: any = jwt(idToken);
				const user = decodedToken;
				// const links = JSON.parse(user.links);
				await register(UID(), 'token', user.name, user.staffId, user.custCode);

				window.location.href = window.location.pathname;
			} else if (!window.location.hash.includes('#id_token')) {
				const user = localStorage.getItem('user');
				if (!user) {
					// window.location.href = process.env.REACT_APP_REDIRECT;
				}
			}
		})();
		// eslint-disable-next-line
	}, []);

	return null;
}
