import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import userIconSVG from 'assets/SVG/leaderboard/user-icon.svg';
import wovenCitySVG from 'assets/SVG/loader/wovenCity.svg';
import closeSVG from 'assets/SVG/close.svg';

export default function UserSidebar({ open, setIsOpen }) {
	const user: any = useSelector<RootState>((state) => state.user);

	return (
		<>
			<div
				className={`fixed right-0 top-0 w-full h-full z-50 bg-black transition-opacity  ${
					open ? 'opacity-50 pointer-events-all' : 'opacity-0 pointer-events-none'
				}`}
				onClick={() => setIsOpen(false)}
			></div>

			<div
				className={`fixed right-0 top-0 w-64 z-50 h-full bg-white transition-transform transform p-4 ${
					open ? '' : 'translate-x-full'
				}`}
			>
				<img className="absolute right-3 top-3 w-7" src={closeSVG} alt="Close" onClick={() => setIsOpen(false)} />
				<div className="w-16 h-16 my-5">
					<img className="w-full h-full rounded-full" src={userIconSVG} alt="" />
				</div>

				<div className="text-md uppercase font-black">{user.fullname}</div>

				<img className="absolute right-6 bottom-6 w-16" src={wovenCitySVG} alt="Woven City" />
			</div>
		</>
	);
}
