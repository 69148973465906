interface ICordinates {
	x: number;
	y: number;
}

export const smallCoordinates: ICordinates[] = [
	{ x: 20, y: 68 }, //bottom
	{ x: 48, y: 65 },
	{ x: 69, y: 54 },
	{ x: 47, y: 34 },
	{ x: 17, y: 37 },
	{ x: 10, y: 16 },
	{ x: 35, y: 4 },
	{ x: 66, y: 0 }, //top
];