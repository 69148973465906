import React, { useEffect } from 'react';
// import { motion } from 'framer-motion';
import playSVG from 'assets/SVG/player/play-white.svg';
import flagsPNG from 'assets/SVG/flags.png';


interface IModuleButton {
	index: number;
	x: number;
	y: number;
	size: number;
	label: string;
	type: 'disabled' | 'passed' | 'active' | 'next';
	isSmall?: boolean;
}

export default function ModuleButton({
	index,
	x = 50,
	y = 50,
	size,
	label,
	type = 'disabled',
	isSmall = false,
}: IModuleButton) {
	const calculateFontSize = () => {
		return `min(${size / 7.6}vw, 1.2rem)`;
	};

	const generateSize = () => {
		return {
			width: `${size}vw`,
			maxWidth: '150px',
			minWidth: `${isSmall ? 'auto' : '75px'}`,
			height: `${size}vw`,
			minHeight: `${isSmall ? 'auto' : '75px'}`,
			maxHeight: '150px',
			left: `${x}%`,
			top: `${y}%`,
		};
	};


	const [score, setScore] = React.useState('');

	useEffect(() => {
		if (JSON.parse(localStorage.getItem('user')).demo) {
			setScore(JSON.parse(localStorage.getItem('user')).demo.quizScore);
			console.log('Quiz score:', JSON.parse(localStorage.getItem('user')).demo.quizScore);
		}
	}, []);

	const generateClass = () => {
		let classNames = 'rounded-full absolute text-white';

		if (type === 'disabled') {
			classNames += ' bg-gray-300 pointer-events-none';
		} else if (type === 'passed') {
			// classNames += ' bg-green-600';
			const isEven = index % 2 === 0;
			if (isEven) {
				classNames += ' bg-gradient-to-t from-darkBlue to-toyotaRed';
			} else classNames += ' bg-gradient-to-r from-purple to-darkBlue';
		} else if (type === 'active' || type === 'next') {
			const isEven = index % 2 === 0;
			if (isEven) {
				classNames += ' bg-gradient-to-t from-darkBlue to-toyotaRed';
			} else classNames += ' bg-gradient-to-r from-purple to-darkBlue';
		}

		return classNames;
	};

	let parseLabel: string | JSX.Element = label;

	if (label === 'START') {
		parseLabel = <img className="w-1/2 h-auto mx-auto pl-1" src={playSVG} alt="Play SVG" />;
	} else if (label === 'TROPHY') {
		parseLabel = <img className="w-1/2 h-auto mx-auto" src={flagsPNG} alt="Trophy SVG" />;
	}

	return (
		<div
			// animate={{ left: `${x}vw`, top: `calc(${y}vh - 4.8rem)` }}
			// transition={{
			// 	duration: 0.6,
			// 	type: 'spring',
			// 	bounce: 0.3,
			// }}
			style={generateSize()}
			className="flex absolute"
		>
			{type === 'next' && (
				<div className="w-full h-full absolute flex items-center justify-center ">
					<div className={`w-2/3 h-2/3 ${generateClass()} animate-ping`}></div>
				</div>
			)}
			<div className={` w-full h-full ${generateClass()} p-1 text-center flex flex-col justify-center items-center`}>
				{!isSmall && (
					<>
						<p className="italic font-semibold" style={{ fontSize: calculateFontSize(), lineHeight: 1.6 }}>
							{parseLabel}
						</p>
						{label === 'KNOWLEDGE QUIZ' && (
							<p className="text-xs -mt-0.5">

								{score}
							</p>
						)}
					</>
				)}
				{/* {isSmall && type === 'active' && (
					<p className="italic font-semibold" style={{ fontSize: calculateFontSize(), lineHeight: 1.6 }}>
						<img className="w-1/2 h-auto mx-auto pl-1" src={playSVG} alt="Play SVG" />
					</p>
				)} */}
			</div>
		</div>
	);
}
