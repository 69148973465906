import React from 'react';
import backIcon from 'assets/SVG/back.svg';
// import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
// import VideoPlayer from 'components/VideoPlayer/VideoPlayer';

interface ISingleVoiceNote {
	data: any;
	close: any;
}

export default function SingleVoiceNote({ data, close }: ISingleVoiceNote) {
	return (
		<div className="fixed top-0 left-0 w-full h-full bg-white px-8 pt-5 z-20">
			<button onClick={close}>
				<img src={backIcon} alt="Back icon" className="w-8" />
			</button>
			<div className="section module-content relative">
				<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
					{/* <AudioPlayer
						artist={data.artist}
						title={data.title}
						image={data.image}
						onEnded={() => {}}
						bigPlayer
						url={data.url}
					/> */}
					<p className="py-4 mt-2 text-1xl font-bold text-gray-500 uppercase">{data.title}</p>
					<h5 className="-mt-2 text-gray-500 font-semibold mb-5">{data.artist}</h5>
					<div className="relative" id="video">
						<video id="videoPlayer" poster={data?.thumb?.url} controls>
							<source src={data?.video?.url} type="video/mp4" />
						</video>
					</div>
				</div>
			</div>
		</div>
	);
}
