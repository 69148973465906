import React from 'react';
import backIcon from 'assets/SVG/back.svg';

interface ISingle360Media {
	data: any;
	close: any;
}

export default function Single360Media({ data, close }: ISingle360Media) {
	return (
		<div className="fixed top-0 left-0 w-full h-full bg-white px-8 pt-5 z-20">
			<button onClick={close}>
				<img src={backIcon} alt="Back icon" className="w-8" />
			</button>
			<div className="section module-content relative">
				<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
					<p className="py-4 mt-2 text-1xl font-bold text-gray-500 uppercase">{data.title}</p>
					<h5 className="-mt-2 text-gray-500 font-semibold mb-5">{data.artist}</h5>
					<div className="relative w-full" id="video">
						<video id="videoPlayer" className="w-full h-52 object-cover" poster={data?.thumbnailUrl} controls>
							<source src={data?.url} type="video/mp4" />
						</video>
					</div>
				</div>
			</div>
		</div>
	);
}
