const getLearningPathById = (learningPathId, learningPathsArray) => {
	return learningPathsArray.filter((learningPath) => learningPath.id === learningPathId);
};

export const getLearningPathData = (learningPaths, pathId, type) => {
	if (!learningPaths) {
		return null;
	}
	const path = getLearningPathById(parseInt(pathId), learningPaths);
	return path[0].steps.filter((step) => step.__component.includes(type))[0];
};

export const getResultsText = (score: number, results) => {
	for (let i = 0; i < results.length; i++) {
		if (score >= results[i].range_start && score <= results[i].range_end) {
			console.log(`${score} >= ${results[i].range_start} && ${score} <= ${results[i].range_end}`);
			return results[i].result_text;
		}
	}
};

export const getNextStep = (learningPaths, currentPath) => {
	const index = learningPaths[0]?.steps.indexOf(currentPath);
	const nextStepObject = learningPaths[0]?.steps[index + 1];
	if (nextStepObject) {
		const nextStep = learningPaths[0]?.steps[index + 1]?.__component?.split('.')[1];
		return nextStep;
	}
	return null;
};

export const getCurrentStep = (learningPaths, currentPath) => {
	//get active level from local storage
	// const user = JSON.parse(localStorage.getItem('user'));
	const activeLevel = 0;
	console.log(activeLevel);
	console.log(learningPaths);
	const index = learningPaths[activeLevel]?.steps.indexOf(currentPath);
	const stepObject = learningPaths[activeLevel]?.steps[index];
	if (stepObject) {
		console.log(
			'learningPaths[activeLevel]?.steps[index]?.__component',
			learningPaths[activeLevel]?.steps[index]?.__component
		);
		const stepPath = learningPaths[activeLevel]?.steps[index]?.__component?.split('.')[1];
		return { index, stepPath };
	}
	return null;
};

export const handleStepOpen = async (learningPaths, data, stepCallback?: Function) => {
	const step = localStorage.getItem('step');
	if (step && step !== 'undefined') {
		const parsedStep = parseInt(step);
		let currentStepIndex = getCurrentStep(learningPaths, data)?.index;

		if (currentStepIndex === 0 && parsedStep === 0) {
			localStorage.setItem('step', '1');
			console.log('1');
			stepCallback && (await stepCallback(1));
			return;
		}
		currentStepIndex && currentStepIndex++;
		if (currentStepIndex && parsedStep < currentStepIndex) {
			localStorage.setItem('step', currentStepIndex + '');
			console.log('2');
			stepCallback && (await stepCallback(currentStepIndex));
		}
	} else {
		localStorage.setItem('step', '1');
		console.log('3');
		stepCallback && (await stepCallback(1));
	}
};

export const handleNextStepOpen = async (learningPaths, currentPath, stepCallback?: Function) => {
	const activeLevel = 0;
	const index = learningPaths[activeLevel]?.steps.indexOf(currentPath);
	const nextStepObject = learningPaths[activeLevel]?.steps[index + 1];

	if (nextStepObject) {
		const nextStepData = learningPaths[activeLevel]?.steps[index + 1];
		await handleStepOpen(learningPaths, nextStepData, stepCallback);
	}
};
